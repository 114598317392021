<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Suggestions" slot="title" link="/helpContent/Suggestion List" />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2" >
                    <FormSelect label="Status" :items="statusList" item-name="Name" item-value="id"  v-model="filtering.selectedStatus" />
                </div>
                <div class="col-sm-2" >
                    <inputDate label="From" v-model="filtering.fromDate" />
                </div>
                <div class="col-sm-2" >
                    <inputDate label="To" v-model="filtering.toDate" />
                </div>
                <div class="col-sm-3">
                    <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                        <Icon type="ios-search" />
                    </FormButton>
                </div>
            </FormRow>
        </ValidationObserver>
        <DataTable :actions="true"
            :data="suggestionList"
            :columns="tableConfig"
            :is-loading="isLoading"
            @on-page-change="pageChange"
            @on-selection-change="selectChange"
            :pagination="pagination"
            @on-sort-change="sortChange">
            <template slot="actionsLeft">
                <TableButton @click="() => add()">
                    <img :src="require(`Assets/images/plus-1.png`)"/>
                  Add
                </TableButton>
                <TableButton @click="() => edit()">
                    <img :src="require(`Assets/images/edit-1.png`)"/>
                  Edit
                </TableButton>
                <TableButton @click="() => remove()">
                    <img :src="require(`Assets/images/del.png`)"/>
                    Delete
                </TableButton>
            </template>
        </DataTable>
        <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
            {{ warningModal.message }}
            <div slot="footer">
                <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel</FormButton>
                <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmDelete">Confirm</FormButton>
                <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok</FormButton>
            </div>
        </Popup>
        <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import InputDate from "Components/form/InputDate";
    import DataTable from 'Components/DataTable';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getSuggestionList, getSuggestionStatus, deleteSuggestion} from '../api';
    import tableConfig from './table';
    import validateSelectedRows from "Mixins/validateSelectedRows";
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "List",
        mixins: [loadingMixin, validateSelectedRows],
        components: {
            FormWrapper,
            InputDate,
            DataTable,
            PageTitle,
            ValidationObserver
        },
        data() {
            return {
                filtering: {
                    selectedStatus: null,
                    fromDate: '',
                    toDate: ''
                },
                sorting: {
                    key: 'department',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                suggestionList: [],
                selectedRows: [],
                statusList: [],
                showMessage: {
                    isVisible: false,
                    message: "",
                }
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getDataByFilter(),
            this.getAllList()
        },
        methods: {
            add(){
                this.$router.push({ path: `/addUpdateSuggestion` });
            },
            remove(){
                if (this.validateSelectedLength(1)) {
                    if (this.deleteSelectedValue()) {
                    }
                }
            },
            edit(){
                if (this.validateSelectedLength(1)) {
                    this.$router.push({ path: `/addUpdateSuggestion/${btoa(this.selectedRows[0].id)}` });
                }
            },
            selectChange(data) {
                this.selectedRows = data;
            },
            sortChange(data) {
                this.sorting.key = data.key;
                this.sorting.order = data.order;
                this.getDataByFilter()
            },
            search(){
                this.pagination.page = 1;
                this.getDataByFilter()
            },
            confirmDelete() {
                this.showWarningModal(false);
                const data = {
                    suggestionId: this.selectedRows[0].id,
                };

                deleteSuggestion(data).then((res) => {
                    if (res.data[0].status > 1) {
                        this.showWarningModal(true);
                        this.selectedRows=[];
                        this.warningModal.buttons.ok =true;
                        this.warningModal.buttons.cancel =false;
                        this.warningModal.buttons.confirm =false;
                        this.warningModal.message = res.data[0].message;
                    } else {
                        this.selectedRows=[];
                        this.warningModal.buttons.ok =true;
                        this.warningModal.buttons.cancel =false;
                        this.warningModal.buttons.confirm =false;
                        this.showMessage.isVisible = true;
                        this.showMessage.message = res.data[0].message;
                        this.getDataByFilter();
                    }
                });
            },
            getDataByFilter() {
                setTimeout(() => {
                    const { key, order } = this.sorting;
                    const { perPage, page } = this.pagination;
                    const filters = {};
                    if (this.filtering.selectedStatus) {
                        filters.statusId = this.filtering.selectedStatus;
                    }
                    if (this.filtering.fromDate) {
                        filters.fromDate = this.filtering.fromDate;
                    }
                    if (this.filtering.toDate) {
                        filters.toDate = this.filtering.toDate;
                    }
                    const data = {
                        filterjson: {
                            filter: [filters],
                            sort: [{ key, order }],
                            paging: [
                                {
                                    startIndex: page,
                                    pageSize: perPage
                                }
                            ]
                        }
                    };
                    getSuggestionList(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            pageChange(page) {
                this.pagination.page = page;
                this.getDataByFilter()
            },
            handleResponse(response) {
                this.hideLoader();
                this.suggestionList = response.data;
                this.pagination.total = response.NumberOfRecords;
            },
            async getAllList() {
                await getSuggestionStatus().then((res)=>{
                    this.statusList=res.data;
                })
            },
        }
    }
</script>

<style lang="scss" module>
    .pagination {
        margin: 10px 0 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        line-height: 26px;
    }

    .paginationText {
        margin: 0 20px;
    }

    .paginationButton {
        cursor: pointer;
        width: 26px;
        text-align: center;
        transition: background-color .2s ease-in-out,color .2s ease-in-out, box-shadow 200ms ease
    }

    .paginationButton:hover {
        background: #57a3f3;
        color: #fff;
        &:hover {
            box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
        }
    }
</style>
