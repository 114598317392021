export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Date',
        key: 'Date',
        minWidth: 60,
        width: 100
    },
    {
        title: 'Added By',
        key: 'Name',
        minWidth: 60,
        width: 125,
    },
    {
        title: 'Department',
        key: 'department',
        minWidth: 60,
        width: 150
    },

    {
        title: 'Category',
        key: 'category',
        minWidth: 60,
        width: 150
    },

    {
        title: 'Status',
        key: 'status',
        minWidth: 60,
        width: 100,
    },
    {
        title: 'Subject',
        key: 'subject',
        minWidth: 60,
        width: 150
    },
    {
        title: 'Comments',
        key: 'comments',
        minWidth: 60,
        width: 150,
    },
    {
        title: 'Suggestions',
        key: 'suggestion',
        minWidth: 60,
        width: 400,
    },





]
